// 'use strict';
let BASE_URL = ''
// let BASE_URL = 'http://127.0.0.1:8082'
// let BASE_URL = 'http://192.168.31.3:8088'


// if (process.env.NODE_ENV === 'development') {
//   BASE_URL = '' 
// } else if (process.env.NODE_ENV === 'testing') {
//   BASE_URL = '' 
// } else if (process.env.NODE_ENV === 'production') {
//   BASE_URL = '' 
// }
// npm run serve 测试运行
// npm run build 线上打包
// npm run test 打包
// npm run dev 线上运行
// console.log(BASE_URL)

//导出
export default BASE_URL
