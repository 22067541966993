import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/components/layout'
import workTable from '@/views/workTable'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('views/login')
  },
  {
    path: '',
    component: Layout,
    redirect: '/dataStatistics',
    meta: { title: '统计', },
    children: [
      // {
      //   path: '/notice',
      //   name: 'notice',
      //   meta: { title: '工作台' },
      //   component: () => import('views/notice')
      // },

      // {
      //   path: '/workbench',
      //   name: 'workbench',
      //   meta: { title: '工作台' },
      //   component: () => import('views/workbench/workbench')
      // },
      {
        path: '/enrollmentList',
        name: 'enrollmentList',
        meta: { title: '招生列表' },
        component: () => import('views/enrollmentList')
      },
      {
        path: '/enrollmentListAdd',
        name: 'enrollmentListAdd',
        meta: { title: '招生列表添加' },
        component: () => import('views/enrollmentList/add')
      },

      {
        path: '/enrollmentAudit',
        name: 'enrollmentAudit',
        meta: { title: '招生审批' },
        component: () => import('views/enrollmentAudit')
      },
      {
        path: '/enrollmentAuditAdd',
        name: 'enrollmentAuditAdd',
        meta: { title: '招生审批' },
        component: () => import('views/enrollmentAudit/add')
      },

      {
        path: '/supervisorList',
        name: 'supervisorList',
        meta: { title: '导师列表' },
        component: () => import('views/supervisorList')
      },
      {
        path: '/supervisorListAdd',
        name: 'supervisorListAdd',
        meta: { title: '导师列表添加' },
        component: () => import('views/supervisorList/add')
      },

      {
        path: '/mentorType',
        name: 'mentorType',
        meta: { title: '导师类型' },
        component: () => import('views/mentorType')
      },

      {
        path: '/traineeList',
        name: 'traineeList',
        meta: { title: '学员列表' },
        component: () => import('views/traineeList')
      },
      {
        path: '/traineeListAdd',
        name: 'traineeListAdd',
        meta: { title: '学员列表添加' },
        component: () => import('views/traineeList/add')
      },
      {
        path: '/classList',
        name: 'classList',
        meta: { title: '班级列表' },
        component: () => import('views/classList')
      },
      {
        path: '/classListAdd',
        name: 'classListAdd',
        meta: { title: '班级列表添加' },
        component: () => import('views/classList/add')
      },
      {
        path: '/courseList',
        name: 'courseList',
        meta: { title: '排课情况' },
        component: () => import('views/courseList')
      },
      {
        path: '/feedbackList',
        name: 'feedbackList',
        meta: { title: '建议反馈' },
        component: () => import('views/feedbackList')
      },
      {
        path: '/feedbackListAdd',
        name: 'feedbackListAdd',
        meta: { title: '建议反馈新增' },
        component: () => import('views/feedbackList/add')
      },
      {
        path: '/curriculumManagement',
        name: 'curriculumManagement',
        meta: { title: '课程管理' },
        component: () => import('views/curriculumManagement')
      },
      {
        path: '/accountManagement',
        name: 'accountManagement',
        meta: { title: '账号管理' },
        component: () => import('views/accountManagement')
      },

      {
        path: '/noteManagement',
        name: 'noteManagement',
        meta: { title: '短信管理' },
        component: () => import('views/consultingManagement/noteManagement')
      },
      {
        path: '/noteManagementAdd',
        name: 'noteManagementAdd',
        meta: { title: '短信管理' },
        component: () => import('views/consultingManagement/noteManagement/add')
      },
      {
        path: '/noteTemplateList',
        name: 'noteTemplateList',
        meta: { title: '短信模板' },
        component: () => import('views/consultingManagement/noteManagement/template')
      },
      {
        path: '/noteTemplateListAdd',
        name: 'noteTemplateListAdd',
        meta: { title: '短信模板' },
        component: () => import('views/consultingManagement/noteManagement/template/add')
      },
      {
        path: '/questionnaireManagement',
        name: 'questionnaireManagement',
        meta: { title: '问卷管理' },
        component: () => import('views/consultingManagement/questionnaireManagement')
      },
      {
        path: '/questionnaireManagementAdd',
        name: 'questionnaireManagementAdd',
        meta: { title: '问卷管理' },
        component: () => import('views/consultingManagement/questionnaireManagement/add')
      },
      {
        path: '/questionnaireManagementLook',
        name: 'questionnaireManagementLook',
        meta: { title: '问卷管理' },
        component: () => import('views/consultingManagement/questionnaireManagement/look')
      },

      {
        path: '/votingManagement',
        name: 'votingManagement',
        meta: { title: '投票管理' },
        component: () => import('views/consultingManagement/votingManagement')
      },
      {
        path: '/votingManagementAdd',
        name: 'votingManagementAdd',
        meta: { title: '投票管理' },
        component: () => import('views/consultingManagement/votingManagement/add')
      },
      {
        path: '/votingManagementLook',
        name: 'votingManagementLook',
        meta: { title: '投票管理' },
        component: () => import('views/consultingManagement/votingManagement/look')
      },
      {
        path: '/consultingManagement',
        name: 'consultingManagement',
        meta: { title: '投票管理' },
        component: () => import('views/consultingManagement/consultingManagement')
      },
      {
        path: '/consultingManagementAdd',
        name: 'consultingManagementAdd',
        meta: { title: '投票管理' },
        component: () => import('views/consultingManagement/consultingManagement/add')
      },

      {
        path: '/signPersonnel',
        name: 'signPersonnel',
        meta: { title: '签到人员' },
        component: () => import('views/classList/signPersonnel')
      },
      
      {
        path: '/systemInformation',
        name: 'systemInformation',
        meta: { title: '系统信息' },
        component: () => import('views/systemManagement/systemInformation')
      },

      {
        path: '/organizationManagement',
        name: 'organizationManagement',
        meta: { title: '组织管理' },
        component: () => import('views/systemManagement/organizationManagement')
      },

      {
        path: '/roleManagement',
        name: 'roleManagement',
        meta: { title: '角色管理' },
        component: () => import('views/systemManagement/roleManagement')
      },

      {
        path: '/personnelManagement',
        name: 'personnelManagement',
        meta: { title: '人员管理' },
        component: () => import('views/systemManagement/personnelManagement')
      },

      {
        path: '/menuManagement',
        name: 'menuManagement',
        meta: { title: '菜单系统' },
        component: () => import('views/systemManagement/menuManagement')
      },
      {
        path: '/activityList',
        name: 'activityList',
        meta: { title: '活动列表' },
        component: () => import('views/activityList')
      },
      {
        path: '/activityListAdd',
        name: 'activityListAdd',
        meta: { title: '活动列表' },
        component: () => import('views/activityList/add')
      },

      {
        path: '/ActivityRegistration',
        name: 'ActivityRegistration',
        meta: { title: '活动报名' },
        component: () => import('views/activityList/ActivityRegistration')
      },

      {
        path: '/consultingManagement',
        name: 'consultingManagement',
        meta: { title: '咨询管理' },
        component: () => import('views/contentManagement/consultingManagement')
      },
      {
        path: '/consultingManagementAdd',
        name: 'consultingManagementAdd',
        meta: { title: '新增咨询管理' },
        component: () => import('views/contentManagement/consultingManagement/add')
      },

      {
        path: '/columnManagement',
        name: 'columnManagement',
        meta: { title: '栏目管理' },
        component: () => import('views/contentManagement/columnManagement')
      },
      {
        path: '/dataStatistics',
        name: 'dataStatistics',
        meta: { title: '数据统计' },
        component: () => import('views/dataStatistics')
      },
      {
        path: '/evaluateList',
        name: 'evaluateList',
        meta: { title: '评价详情' },
        component: () => import('views/supervisorList/evaluateList')
      },
      {
        path: '/trainingList',
        name: 'trainingList',
        meta: { title: '集训名单' },
        component: () => import('views/enrollmentList/trainingList')
      },
      {
        path: '/trainingList2',
        name: 'trainingList2',
        meta: { title: '集训名单' },
        component: () => import('views/enrollmentAudit/trainingList')
      },
      {
        path: '/bannerList',
        name: 'bannerList',
        meta: { title: 'banner列表' },
        component: () => import('views/BANNER/list')
      },
      {
        path: '/bannerListAdd',
        name: 'bannerListAdd',
        meta: { title: 'banner列表添加' },
        component: () => import('views/BANNER/list/add')
      },

      {
        path: '/imgDetails',
        name: 'imgDetails',
        meta: { title: '相册详情' },
        component: () => import('views/classList/imgDetails')
      }

      
      
      
      

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
