import Request from '@/utils/api'

// 登入
export const  login = (params) => Request.post('/ZDSchoolManager/SysAdmin/login', params)

// 届&批次列表
export const  year_and_batch_list = (params) => Request.post('/ZDSchoolManager/Scheduling/year_and_batch_list', params)

// 届&班级列表
export const  class_list = (params) => Request.post('/ZDSchoolManager/Scheduling/class_list', params)

// 总课表
export const  sel_schedule_by_data = (params) => Request.post('/ZDSchoolManager/Scheduling/sel_schedule_by_data', params)

// 总课表
export const  teacher_list = (params) => Request.post('/ZDSchoolManager/Scheduling/teacher_list', params)

// 日历及右边教师数量和课程数量
export const  sel_schedule_history = (params) => Request.post('/ZDSchoolManager/Scheduling/sel_schedule_history', params)

// 根据班级日期查看排课
export const  sel_schedule_by_class_and_data = (params) => Request.post('/ZDSchoolManager/Scheduling/sel_schedule_by_class_and_data', params)

// 新增，编辑，删除计划

export const  save_schedule = (params) => Request.post('/ZDSchoolManager/Scheduling/save_schedule', params)

// 招生列表
export const  recruit_list = (params) => Request.post('/ZDSchoolManager/Recruit/recruit_list', params)

//  七牛云token
export const  sel_qiniu_token = (params) => Request.post('/ZDSchoolManager/Msg/sel_qiniu_token', params)

//  招生列表--新增，编辑
export const  add_upd_recruit_list = (params) => Request.post('/ZDSchoolManager/Recruit/add_upd_recruit_list', params)

//  招生列表--删除
export const  del_recruit_list = (params) => Request.post('/ZDSchoolManager/Recruit/del_recruit_list', params)

//  招生列表--查看
export const  sel_recruit_list_details = (params) => Request.post('/ZDSchoolManager/Recruit/sel_recruit_list_details', params)

//  新增招生--班级选择
export const  sel_class_list = (params) => Request.post('/ZDSchoolManager/Recruit/sel_class_list', params)

//  新增招生--班级选择--保存
export const  save_class_list = (params) => Request.post('/ZDSchoolManager/Recruit/save_class_list', params)

//  导师列表
export const  tutor_list = (params) => Request.post('/ZDSchoolManager/Tutor/tutor_list', params)

//  导师列表--更新状态
export const  upd_tutor_list_status = (params) => Request.post('/ZDSchoolManager/Tutor/upd_tutor_list_status', params)

//  导师列表--删除
export const  del_tutor_list = (params) => Request.post('/ZDSchoolManager/Tutor/del_tutor_list', params)

//  导师列表--新增编辑
export const  add_upd_tutor_list = (params) => Request.post('/ZDSchoolManager/Tutor/add_upd_tutor_list', params)

//  导师列表--查看
export const  sel_tutor_list_details = (params) => Request.post('/ZDSchoolManager/Tutor/sel_tutor_list_details', params)

//  导师课程列表
export const  tutor_class_list = (params) => Request.post('/ZDSchoolManager/Tutor/tutor_class_list', params)

//  导师课程保存
export const  add_teacher_and_course = (params) => Request.post('/ZDSchoolManager/Tutor/add_teacher_and_course', params)

//  导师课程日历
export const  sel_schedule_by_teacher_and_data = (params) => Request.post('/ZDSchoolManager/Tutor/sel_schedule_by_teacher_and_data', params)

//  导师课程--评价列表
export const  sel_course_score_list = (params) => Request.post('/ZDSchoolManager/Tutor/sel_course_score_list', params)

//  学员列表
export const  stu_list = (params) => Request.post('/ZDSchoolManager/Student/stu_list', params)

//  更改状态
export const  upd_stu_list = (params) => Request.post('/ZDSchoolManager/Student/del_stu_list', params)

//  新增
export const  add_stu_list = (params) => Request.post('/ZDSchoolManager/Student/edit_stu_list', params)

//  反馈列表删除
export const  sel_suggest_list = (params) => Request.post('/ZDSchoolManager/ClassSign/sel_suggest_list', params)

//  反馈列表删除
export const  del_suggest_list = (params) => Request.post('/ZDSchoolManager/ClassSign/del_suggest_list', params)

//  查看
export const  sel_suggest_list_details = (params) => Request.post('/ZDSchoolManager/ClassSign/sel_suggest_list_details', params)

//  保存
export const  save_suggest_list_details_answer = (params) => Request.post('/ZDSchoolManager/ClassSign/save_suggest_list_details_answer', params)

//  课程列表
export const  sel_class_manage = (params) => Request.post('/ZDSchoolManager/Statistics/sel_class_manage', params)

//  课程列表删除
export const  del_class_manage = (params) => Request.post('/ZDSchoolManager/Statistics/del_class_manage', params)

//  课程列表新增
export const  add_upd_class_manage = (params) => Request.post('/ZDSchoolManager/Statistics/add_upd_class_manage', params)

//  账号列表
export const  sys_org_person_list = (params) => Request.post('/ZDSchoolManager/SysAdmin/sys_org_person_list', params)

//  账号列表新增更新
export const  add_upd_sys_org_person_list = (params) => Request.post('/ZDSchoolManager/SysAdmin/add_upd_sys_org_person_list', params)

//  账号列表删除
export const  del_sys_org_person_list = (params) => Request.post('/ZDSchoolManager/SysAdmin/del_sys_org_person_list', params)

//  短信列表
export const  sel_msg_message = (params) => Request.post('/ZDSchoolManager/Msg/sel_msg_message', params)

//  活动列表--删除
export const  del_msg_message = (params) => Request.post('/ZDSchoolManager/Msg/del_msg_message', params)

//  短信列表 - 模版管理 - 列表
export const  sel_sys_msg_message_model = (params) => Request.post('/ZDSchoolManager/Msg/sel_sys_msg_message_model', params)

// 短信列表 - 发送范围
export const  sel_send_ratio = (params) => Request.post('/ZDSchoolManager/Msg/sel_send_ratio', params)

// 短信列表 - 短信模版 - 列表 - 新增、编辑
export const  add_update_sys_msg_message_model = (params) => Request.post('/ZDSchoolManager/Msg/add_update_sys_msg_message_model', params)

// 短信列表 - 短信模版 - 列表 - 删除
export const  del_msg_message_model = (params) => Request.post('/ZDSchoolManager/Msg/del_msg_message_model', params)

// 短信列表 - 短信模版 - 列表 - 启用、禁用
export const  upd_msg_message_model_status = (params) => Request.post('/ZDSchoolManager/Msg/upd_msg_message_model_status', params)

// 短信列表 - 新增、编辑
export const  add_upd_msg_message = (params) => Request.post('/ZDSchoolManager/Msg/add_upd_msg_message', params)

// 短信列表 - 下架、发布
export const  upd_msg_message_status = (params) => Request.post('/ZDSchoolManager/Msg/upd_msg_message_status', params)

// ai列表
export const  sel_vote_list = (params) => Request.post('/ZDSchoolManager/Vote/sel_vote_list', params)

// ai列表
export const  upd_vote_tb = (params) => Request.post('/ZDSchoolManager/Vote/upd_vote_tb', params)


// ai查看
export const  sel_vote_list_details = (params) => Request.post('/ZDSchoolManager/Vote/sel_vote_list_details', params)

// ai保存
export const  add_upd_vote_tb_details = (params) => Request.post('/ZDSchoolManager/Vote/add_upd_vote_tb_details', params)

// 列表
export const  SysParmetersList = (params) => Request.post('/ZDSchoolManager/SysParmeters/list', params)

// 导师列表-新增-证书列表
export const  Tutorcer_list = (params) => Request.post('/ZDSchoolManager/Tutor/cer_list', params)


// 导师列表-新增-证书列表-删除
export const  Tutorcer_del = (params) => Request.post('/ZDSchoolManager/Tutor/cer_del', params)


// 导师类型列表 - 删除
export const  SysParmetersDel = (params) => Request.post('/ZDSchoolManager/SysParmeters/del', params)

// 导师类型列表 - 保存
export const  SysParmetersedit = (params) => Request.post('/ZDSchoolManager/SysParmeters/edit', params)

// 导师列表-新增-证书列表-新增
export const  Tutorcer_edit = (params) => Request.post('/ZDSchoolManager/Tutor/cer_edit', params)

// 班级列表
export const  SysStudentAndClasslist = (params) => Request.post('/ZDSchoolManager/SysStudentAndClass/class/list', params)

// 班级列表-shanchu
export const  SysStudentAndClasslistdelete = (params) => Request.post('/ZDSchoolManager/SysStudentAndClass/class/delete', params)

// 班级列表-shanchu
export const  SysStudentAndClassedit = (params) => Request.post('/ZDSchoolManager/SysStudentAndClass/class/edit', params)

// 导师课程列表
export const  sel_class_course_list_details = (params) => Request.post('/ZDSchoolManager/ClassSign/sel_class_course_list_details', params)

// 导师课程列表--保存
export const  save_class_course_list_details = (params) => Request.post('/ZDSchoolManager/ClassSign/save_class_course_list_details', params)

// 学员列表
export const  sel_class_stu_list = (params) => Request.post('/ZDSchoolManager/ClassSign/sel_class_stu_list', params)

// 签到列表
export const  class_sign_list = (params) => Request.post('/ZDSchoolManager/ClassSign/class_sign_list', params)

// 学员列表--班级信息-新增-班级选择
export const  stu_list_add_class_option = (params) => Request.post('/ZDSchoolManager/Student/stu_list_add_class_option', params)

// 学员列表--班级信息-新增
export const  stu_list_add_class = (params) => Request.post('/ZDSchoolManager/Student/stu_list_add_class', params)

// 学员列表--班级信息-删除
export const  stu_list_del_class = (params) => Request.post('/ZDSchoolManager/Student/stu_list_del_class', params)

// 签到人员表
export const  class_sign_person_list = (params) => Request.post('/ZDSchoolManager/ClassSign/class_sign_person_list', params)

// 更改签到状态
export const  upd_class_sign_person_list = (params) => Request.post('/ZDSchoolManager/ClassSign/upd_class_sign_person_list', params)

// 班级管理--学员列表-删除
export const  del_class_stu_list = (params) => Request.post('/ZDSchoolManager/ClassSign/del_class_stu_list', params)

// 班级管理--学员列表-新增-选择学员
export const  add_class_stu_list_option_class = (params) => Request.post('/ZDSchoolManager/ClassSign/add_class_stu_list_option_class', params)

// 班级管理--学员列表-新增
export const  add_class_stu_list = (params) => Request.post('/ZDSchoolManager/ClassSign/add_class_stu_list', params)

// 班级附件列表
export const  sel_class_files_list = (params) => Request.post('/ZDSchoolManager/ClassSign/sel_class_files_list', params)

// 班级附件列表--详情--保存
export const  save_class_files_list = (params) => Request.post('/ZDSchoolManager/ClassSign/save_class_files_list', params)

// 班级附件列表-删除
export const  del_class_files_list = (params) => Request.post('/ZDSchoolManager/ClassSign/del_class_files_list', params)

// 班级风采--列表
export const  sel_class_style_list = (params) => Request.post('/ZDSchoolManager/Tutor/sel_class_style_list', params)

// 班级风采--新增图片
export const  add_upd_class_style_list_details = (params) => Request.post('/ZDSchoolManager/Tutor/add_upd_class_style_list_details', params)

// 班级风采--删除图片
export const  del_class_style_list = (params) => Request.post('/ZDSchoolManager/Tutor/del_class_style_list', params)

// 班级 -状态变更
export const  statuSupdate = (params) => Request.post('/ZDSchoolManager/SysStudentAndClass/class/status/update', params)

// 班级 -状态变更
export const  update_stu_list_option_to_teacher = (params) => Request.post('/ZDSchoolManager/ClassSign/update_stu_list_option_to_teacher', params)

// 班级 -状态变更
export const  roleList = (params) => Request.post('/ZDSchoolManager/SysRole/all/list', params)

// 班级 -状态变更
export const  roleListDelete = (params) => Request.post('/ZDSchoolManager/SysRole/delete', params)

// 班级 -状态变更
export const  roleListAdd = (params) => Request.post('/ZDSchoolManager/SysRole/add', params)

// 班级 -状态变更
export const  roleListUpdate = (params) => Request.post('/ZDSchoolManager/SysRole/update', params)

// 菜单列表
export const  SysMenuAllMenu = (params) => Request.post('/ZDSchoolManager/SysMenu/all/menu', params)

// 菜单列表
export const  SysMenuAllMenuDelete = (params) => Request.post('/ZDSchoolManager/SysMenu/delete', params)

// 菜单列表
export const  SysMenuAllMenuAdd = (params) => Request.post('/ZDSchoolManager/SysMenu/add', params)

// 菜单列表
export const  SysMenuAllMenuUpdate = (params) => Request.post('/ZDSchoolManager/SysMenu/update', params)

// 菜单列表
export const  SysEnterpriseb_list = (params) => Request.post('/ZDSchoolManager/SysAdmin/sys_account_list', params)

// 删除
export const  b_listDel = (params) => Request.post('/ZDSchoolManager/SysAdmin/del_sys_account_list', params)

// 岗位
export const  sel_sys_account_list_edit_option_job = (params) => Request.post('/ZDSchoolManager/SysAdmin/sel_sys_account_list_edit_option_job', params)


// 岗位
export const  update_state_sys_account = (params) => Request.post('/ZDSchoolManager/SysAdmin/update_state_sys_account', params)

// 岗位
export const  edit_sys_account_list = (params) => Request.post('/ZDSchoolManager/SysAdmin/edit_sys_account_list', params)

// 岗位
export const  SysBasicToolChina = (params) => Request.post('/ZDSchoolManager/SysBasicTool/china', params)

// 基础信息 - 详情
export const  sel_org_info = (params) => Request.post('/ZDSchoolManager/SysOrgDept/sel_org_info', params)

// 基础信息 - 更新
export const  update_org_info = (params) => Request.post('/ZDSchoolManager/SysOrgDept/update_org_info', params)

// 省、市、区、街道 - 列表
export const  SysBasicToolList = (params) => Request.post('/ZDSchoolManager/SysBasicTool/list', params)

// 省、市、区、街道 - 列表 - 更新 - 选择管理员
export const  sel_list_update_option_account = (params) => Request.post('/ZDSchoolManager/SysBasicTool/sel_list_update_option_account', params)

// 省、市、区、街道 - 列表 - 更新 - 选择管理员
export const  list_update = (params) => Request.post('/ZDSchoolManager/SysBasicTool/list_update', params)

// 招生类型
export const  recruit_type_list = (params) => Request.post('/ZDSchoolManager/Recruit/recruit_type_list', params)



// 招生列表--招生设置--保存
export const  add_upd_china_combobox_details = (params) => Request.post('/ZDSchoolManager/Recruit/add_upd_china_combobox_details', params)

// sel_china_combobox_details_list
export const  sel_china_combobox_details_list = (params) => Request.post('/ZDSchoolManager/Recruit/sel_china_combobox_details_list', params)

// 招生列表--普通人员列表
export const  sel_china_combobox_right_stu_list_nomal = (params) => Request.post('/ZDSchoolManager/Recruit/sel_china_combobox_right_stu_list_nomal', params)

// 招生列表--人员--新增
export const  edit_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/edit_stu_list', params)

// 招生简章--普通区域--人员删除
export const  del_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/del_stu_list', params)

// 招生简章--普通区域--人员审批
export const  RecruitUpd_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/upd_stu_list', params)

// 查看详情
export const  sel_stu_list_detail = (params) => Request.post('/ZDSchoolManager/Recruit/sel_stu_list_detail', params)


// 活动列表
export const  active_list = (params) => Request.post('/ZDSchoolManager/Active/active_list', params)

// 活动列表--删除
export const  del_active_list = (params) => Request.post('/ZDSchoolManager/Active/del_active_list', params)

// 名称活动列表--新增--编辑

export const  add_active_list = (params) => Request.post('/ZDSchoolManager/Active/add_active_list', params)


// 活动列表--新增--编辑 -选择班级

export const  sel_active_list_option_class = (params) => Request.post('/ZDSchoolManager/Active/sel_active_list_option_class', params)

// 活动列表--新增--编辑 -选择班级

export const  sel_active_list = (params) => Request.post('/ZDSchoolManager/Active/sel_active_list', params)

// 活动列表--更新状态

export const  upd_active_list_status = (params) => Request.post('/ZDSchoolManager/Active/upd_active_list_status', params)

// 活动列表-查看-人员列表

export const  students_list = (params) => Request.post('/ZDSchoolManager/Active/students_list', params)

// 活动列表-查看-人员列表-新增-选择学生

export const  sel_students_list_add_option_stu = (params) => Request.post('/ZDSchoolManager/Active/sel_students_list_add_option_stu', params)

// 动列表-查看-人员列表-新增--保存

export const  save_students_list_application = (params) => Request.post('/ZDSchoolManager/Active/save_students_list_application', params)

// 活动列表-查看-人员列表-删除

export const  del_students_list_application = (params) => Request.post('/ZDSchoolManager/Active/del_students_list_application', params)


// 活动列表-查看-人员列表-删除

export const  update_students_list_application_status = (params) => Request.post('/ZDSchoolManager/Active/update_students_list_application_status', params)

// 活动列表-查看-人员列表-删除

export const  sys_info = (params) => Request.post('/ZDSchoolManager/SysOrgDept/sys_info', params)

// 内容列表
export const  sel_content_list = (params) => Request.post('/ZDSchoolManager/Content/sel_content_list', params)

// 内容列表-删除
export const  del_content_list = (params) => Request.post('/ZDSchoolManager/Content/del_content_list', params)

// 内容列表-撤销
export const  del_approval_process = (params) => Request.post('/ZDSchoolManager/Content/del_approval_process', params)

// 内容列表-提交
export const  approval_content = (params) => Request.post('/ZDSchoolManager/Content/approval_content', params)

// 活动管理-操作
export const  active_state = (params) => Request.post('/ZDSchoolManager/Banner/active_state', params)

// 内容列表-新增
export const  save_content = (params) => Request.post('/ZDSchoolManager/Content/save_content', params)

// 内容列表-编辑
export const  upd_content = (params) => Request.post('/ZDSchoolManager/Content/upd_content', params)

// 内容列表-详情
export const  sel_content_list_details = (params) => Request.post('/ZDSchoolManager/Content/sel_content_list_details', params)

// 栏目下拉框
export const  find_item_list = (params) => Request.post('/ZDSchoolManager/Content/find_item_list', params)

// 获取文章
export const  trynew = (params) => Request.post('/ZDSchoolManager/Wikipedia/try/news', params)

// 栏目列表（详情也直接取这里把，里面没什么东西）
export const  item_list = (params) => Request.post('/ZDSchoolManager/Content/item_list', params)

export const  item_del = (params) => Request.post('/ZDSchoolManager/Content/item_del', params)

// 栏目列表-新增
export const  item_add = (params) => Request.post('/ZDSchoolManager/Content/item_add', params)

// 栏目列表-编辑
export const  item_upd = (params) => Request.post('/ZDSchoolManager/Content/item_upd', params)

// 获取菜单
export const  userList = (params) => Request.post('/ZDSchoolManager/SysMenu/user/list', params)

// 获取菜单
export const  sel_course_score_list_details = (params) => Request.post('/ZDSchoolManager/Tutor/sel_course_score_list_details', params)

// 参训名单列表
export const  recruit_list_pass_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/recruit_list_pass_stu_list', params)

// 参训名单列表
export const  del_recruit_list_pass_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/del_recruit_list_pass_stu_list', params)

// 参训名单列表
export const  add_recruit_list_pass_stu_list_opetion_class_option_class = (params) => Request.post('/ZDSchoolManager/Recruit/add_recruit_list_pass_stu_list_opetion_class_option_class', params)

// 参训名单列表
export const  add_recruit_list_pass_stu_list_opetion_class = (params) => Request.post('/ZDSchoolManager/Recruit/add_recruit_list_pass_stu_list_opetion_class', params)

// 参训名单列表
export const  sel_msg_send_recode = (params) => Request.post('/ZDSchoolManager/Msg/sel_msg_send_recode', params)

// 报名统计 - 学员列表-选择届
export const  student_list_option_session = (params) => Request.post('/ZDSchoolManager/Statistics/student_list_option_session', params)

// 报名统计 - 学员列表-选择班级
export const  student_list_option_class = (params) => Request.post('/ZDSchoolManager/Statistics/student_list_option_class', params)

// 报名统计 - 报名班级统计
export const  registration_statistics = (params) => Request.post('/ZDSchoolManager/Statistics/registration_statistics', params)

// 报名统计 - 招生
export const  registration_statistics_recruit = (params) => Request.post('/ZDSchoolManager/Statistics/registration_statistics_recruit', params)

// 报名统计 - 学员列表
export const  student_list = (params) => Request.post('/ZDSchoolManager/Statistics/student_list', params)

// 课程统计 - 课程
export const  course_statistics = (params) => Request.post('/ZDSchoolManager/Statistics/course_statistics', params)

// 课程统计 - 签到
export const  sign_statistics = (params) => Request.post('/ZDSchoolManager/Statistics/sign_statistics', params)

// 课程统计 - 评分
export const  score_statistics = (params) => Request.post('/ZDSchoolManager/Statistics/score_statistics', params)

// 课程统计 - 签到评分列表
export const  sign_score_statistics = (params) => Request.post('/ZDSchoolManager/Statistics/sign_score_statistics', params)

// 问卷统计 - 问卷列表
export const  sel_vote_answerer_statistics_vote_list = (params) => Request.post('/ZDSchoolManager/Statistics/sel_vote_answerer_statistics_vote_list', params)

// 问卷统计 - 统计
export const  vote_answerer_statistics = (params) => Request.post('/ZDSchoolManager/Statistics/vote_answerer_statistics', params)

//问卷统计 - 答题趋势统计
export const  vote_answerer_statistics_count = (params) => Request.post('/ZDSchoolManager/Statistics/vote_answerer_statistics_count', params)

//问卷统计 - 答卷列表
export const  vote_answerer_list = (params) => Request.post('/ZDSchoolManager/Statistics/vote_answerer_list', params)

//问卷统计 - 答卷列表
export const  vote_answerer_list_detail = (params) => Request.post('/ZDSchoolManager/Statistics/vote_answerer_list_detail', params)

//问卷统计 - 答题统计
export const  sel_vote_answerer_subject_count = (params) => Request.post('/ZDSchoolManager/Statistics/sel_vote_answerer_subject_count', params)

//资讯列表 - 通过，驳回
export const  update_content_list_apply_state = (params) => Request.post('/ZDSchoolManager/Content/update_content_list_apply_state', params)

//招生审批
export const  sel_biz_recruit_list = (params) => Request.post('/ZDSchoolManager/Recruit/sel_biz_recruit_list', params)

//招生审批 - 参训人员
export const  sel_recruit_list_pass_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/sel_recruit_list_pass_stu_list', params)

//招生审批 - 参训人员 - 通过、驳回
export const  update_biz_upd_stu_list = (params) => Request.post('/ZDSchoolManager/Recruit/update_biz_upd_stu_list', params)

//excel预览功能
export const  excel_to_see = (params) => Request.post('/ZDSchoolManager/SysUploadFiles/excel_to_see', params)

//模版下载
export const  excel_model_download = (params) => Request.post('/ZDSchoolManager/SysUploadFiles/excel_model_download', params)

//导师管理-排课管理-默认展现最后一条数据的月份
export const  sel_schedule_history_last_data_day = (params) => Request.post('/ZDSchoolManager/Scheduling/sel_schedule_history_last_data_day', params)

// 运营管理
// banner列表
export const  banner_list = (params) => Request.post('/ZDSchoolManager/Banner/banner_list', params)

// banner开启管理
export const  banner_open_close = (params) => Request.post('/ZDSchoolManager/Banner/banner_open_close', params)

// banner删除
export const  banner_del = (params) => Request.post('/ZDSchoolManager/Banner/banner_del', params)

// banner新增
export const  banner_add = (params) => Request.post('/ZDSchoolManager/Banner/banner_add', params)

// banner编辑
export const  banner_upd = (params) => Request.post('/ZDSchoolManager/Banner/banner_upd', params)

// banner查看
export const  banner_sel_detail = (params) => Request.post('/ZDSchoolManager/Banner/banner_sel_detail', params)

// 多文件在线压缩
export const  files_mkzip = (params) => Request.post('/ZDSchoolManager/SysUploadFiles/files_mkzip', params)

// 相册 - 列表
export const  sel_class_style_album_list = (params) => Request.post('/ZDSchoolManager/Tutor/sel_class_style_album_list', params)


// 相册 - 编辑
export const  edit_class_style_list_details = (params) => Request.post('/ZDSchoolManager/Tutor/edit_class_style_list_details', params)

// 相册 - 删除
export const  del_class_style_list_details = (params) => Request.post('/ZDSchoolManager/Tutor/del_class_style_list_details', params)

// 招生列表 - 报名信息 - 导入 - excel类型
export const  sel_recruit_list_batch_import_excel_category = (params) => Request.post('/ZDSchoolManager/Recruit/sel_recruit_list_batch_import_excel_category', params)


// 学员列表
export const  sel_stu_file_list = (params) => Request.post('/ZDSchoolManager/Student/sel_stu_file_list', params)

// 学员列表-删除
export const  del_stu_file_list  = (params) => Request.post('/ZDSchoolManager/Student/del_stu_file_list', params)

// 学员列表-编辑
export const  edit_stu_file_list  = (params) => Request.post('/ZDSchoolManager/Student/edit_stu_file_list', params)

// 模板
export const  sel_import_excel_categor  = (params) => Request.post('/ZDSchoolManager/ImportExcel/sel_import_excel_category', params)












































