// let hostname = 'http://127.0.0.1:8082'
// let target = 'http://192.168.31.190:8082'
let hostname = ''
let target = ''
// let target = 'http://127.0.0.1:8082'

let time = new Date()
let y = time.getFullYear()
let m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : `0${time.getMonth() + 1}`
let d = time.getDate() > 9 ? time.getDate() : `0${time.getDate()}`
const today = `${y}-${m}-${d}`

export default {
  hostname,
  target,
  today
}